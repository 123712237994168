<dpa-modal
  [modalOpen]="isConfirmLeaveModalOpen$ | async"
  [modalSize]="ModalSize.MD"
  (onModalClose)="cancelNavigateAway()"
>
  <dpa-modal-title>
    {{ 'WIZARD.EXIT_PAGE' | translate }}
  </dpa-modal-title>
  <div class="modal-body">
    {{ 'WIZARD.UNSAVED_CHANGES_DESCRIPTION' | translate }}
    @if (pendingMessage$ | async; as pendingMessage) {
      <p class="p7 pending-message">
        {{ pendingMessage }}
      </p>
    }
  </div>
  <div class="modal-footer">
    <button
      (click)="cancelNavigateAway()"
      class="btn btn-outline"
    >
      {{ 'COMMON_ACTIONS.CANCEL' | translate }}
    </button>
    <button
      (click)="confirmNavigateAway()"
      class="btn btn-danger"
    >
      {{ 'COMMON_ACTIONS.EXIT' | translate }}
    </button>
  </div>
</dpa-modal>
